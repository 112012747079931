import React, { useEffect, useRef, useState } from 'react'
import { CodemirrorBinding } from 'y-codemirror'
import { UnControlled as CodeMirrorEditor } from 'react-codemirror2'
import * as Y from 'yjs'
import { WebrtcProvider } from 'y-webrtc'
import './editor.css'
import RandomColor from 'randomcolor'
import './EditorAddons'
import { useSelector } from 'react-redux'
import { useHttp } from '../../hooks/http.hook'


export function Editor({ setCursor, setCodeForCheck, setUsersInTab, fileContent, ...props }) {
  const view = useRef(null)
  const [EditorRef, setEditorRef] = useState(null)
  const auth = useSelector(state => state.auth)
  const [code, setCode] = useState('')
  const { loading, request, error, clearError } = useHttp()
  const [readOnly, setReadOnly] = useState("")
  const [usersInDoc, setUsersInDoc] = useState(0)
  const [YText, setYText] = useState(0)
  const handleEditorDidMount = (editor) => {
    setEditorRef(editor)
  }
  const [ok, setOk] = useState(false)


  useEffect(() => {
    props.socket?.on("changePermissionOnClient", userPermissionInRoom => {
      // console.log("IN EDITOR PERMISSION--", userPermissionInRoom)
      setReadOnly(userPermissionInRoom.permission)
    })
  }, [props.socket])

  const onYjsChange = (event, tr) => {
    // console.log('event:', event)
    // console.log('transaction:', tr)

    // const yText = tr.doc.getText('codemirror')
    // console.log('STRING', yText.toString())
    // console.log('Local', tr?.local)
    // setLocal(tr?.local)
    // console.log('fileContent', fileContent)
    // console.log('webrtcConns', tr?.origin?.webrtcConns.size)

    const yText = tr.doc.getText('codemirror')
    // console.log('yText.toString()', `'${yText.toString()}'`)
    // console.log('tr?.local', tr?.local)
    // console.log('local', local)
    // console.log('---------------------------------------')
    // yText.delete(0, 1)
    if (!tr?.local && EditorRef?.getValue() && yText.toString() && `${EditorRef?.getValue()}${EditorRef?.getValue()}` === yText.toString()) {
      console.log('EditorRef.getValue()', EditorRef.getValue())
      console.log('yText.toString()', `'${yText.toString()}'`)
      console.log('yText.toDelta()', `'${JSON.stringify(yText.toDelta(), null, 2)}'`)
      yText.delete(0, EditorRef.getValue().length)
      // EditorRef.setValue(yText.toString())
    }
    // if (tr?.local && !yText.toString() && fileContent) {
    //   console.log('INSERT CONTENT', `'${fileContent}'`)
    //   yText.insert(0, fileContent)
    // }
  }

  // const docOnTr = (tr, doc) => {
  //   if (tr?.origin) {
  //     const yText = tr.doc.getText(props?.path?.path)
  //     // console.log('path', props?.path?.path)
  //     console.log('Tr.origin', tr.origin)
  //     // console.log('Str', yText.toString())
  //     console.log('name', tr?.origin?.name === props?.path?.path)
  //     console.log('sync', tr?.origin?.synced)
  //     console.log('webrtcConns', tr?.origin?.webrtcConns.size)
  //     console.log('Str', yText.toString())
  //     console.log('content', fileContent)
  //     if (tr?.origin?.name === props?.path?.path && !tr?.origin?.webrtcConns.size && !yText.toString() && fileContent) {
  //       yText.insert(0, fileContent || '')
  //     }
  //   }
  // }

  useEffect(() => {
    if (EditorRef && props?.path?.path && !props?.path?.children) {
      const ydoc = new Y.Doc() //create a ydoc
      // ydoc.on('afterTransaction', docOnTr)

      let provider = null
      try {
        provider = new WebrtcProvider(props.path.path, ydoc, { //Remember the other tab or
          //other user should be in same room for seeing real-time changes
          signaling: [
            'wss://signaling.yjs.dev',
            // 'stun:stun4.l.google.com:19302',
            // 'wss://y-webrtc-signaling-eu.herokuapp.com',
            // 'wss://y-webrtc-signaling-us.herokuapp.com'
          ]
        })

        const yText = ydoc.getText('codemirror')
        // setTimeout(() => {
          yText.observe(onYjsChange)
          // yText.insert(0, ' ')
          // yText.delete(0, 1)
        // }, 300)

        // console.log(provider.connected)
        // if (provider.connected && !yText.toString() && fileContent) {
        //   yText.insert(0, fileContent || '')
        // }
        // setYText(yText)


        const yUndoManager = new Y.UndoManager(yText)

        let awareness = provider.awareness //awareness is what makes other user aware about your actions

        awareness.on('update', changes => {  // 'change' срабатывает только при изменении, 'update' - при старте и раз в 5 сек
          // Whenever somebody updates their awareness information,
          // we log all awareness information from all users.
          let userCount = Array.from(awareness?.getStates()?.values())
          // console.log('yText', yText.toString())
          // console.log("кол-во юзеров в документе", userCount)
          // console.log("yText--", yText?.doc.store.clients )
          setUsersInDoc(userCount?.length || 0)
          setUsersInTab(userCount || [])
        })

        const color = RandomColor() //Provied any random color to be used for each user

        awareness.setLocalStateField('user', {
          name: auth?.user?.email || 'User',
          color: props.userColor,
        })

        // setYdocUsers(  awareness.states)
        const getBinding = new CodemirrorBinding(yText, EditorRef, awareness, {
          yUndoManager,
        })


      } catch (err) {
        alert('error in collaborating try refreshing or come back later !')
      }
      return () => {
        if (provider) {
          provider.disconnect() //We destroy doc we created and disconnect
          ydoc.destroy()  //the provider to stop propagting changes if user leaves editor
        }
      }
    }
  }, [EditorRef, props.path?.path])

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (fileContent && !code) {
  //       setCode(prev => fileContent)
  //     }
  //   }, 1000)
  // }, [fileContent, code])

  // useEffect(() => {
  //   if (EditorRef?.getValue() !== fileContent) {
  //     setFc(fileContent)
  //   }
  // }, [props?.path])

  useEffect(() => {
    // console.log('fileContent', fileContent)
    // console.log('EditorRef', EditorRef)
    // console.log('Editor Val prev', EditorRef?.getValue())
    if (!ok && fileContent && EditorRef && EditorRef.getValue() !== fileContent && !EditorRef.getValue() && !props?.path?.children) {
      console.log('SET fileContent', `'${fileContent}'`)
      EditorRef.setValue(fileContent)
      fileContent = null
    }
    // console.log('Editor Val post', EditorRef?.getValue())
    // console.log('---------------------------------------')
  }, [props.path?.path])

  function calcCursorOnClick(pos) {
    setCursor({ ln: pos.line, col: pos.ch })
  }

  const saveFileContent = async (path, content) => {
    try {
      // console.log(content)
      if (content) {
        const fileName = await request('/api/project/saveFileContent', 'POST', { path: path.path, content: content }, { authorization: 'Bearer ' + auth.token })
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className='w-full h-full overflow-auto scrollbar-hide md:scrollbar-default mt-[30px] border-t-[1px] border-[#30363D]  '>

      <CodeMirrorEditor
        className='w-full'
        // value={code}
        onCursor={(editor, data) => {
          calcCursorOnClick(data)
        }}
        onChange={(editor, data, value) => {
          saveFileContent(props.path, value)
          // setCode(value)
          setCodeForCheck(value)
        }}
        ref={view}
        autoScroll
        options={{
          mode: props.language, //this is for c++,  you can visit https://github.com/atharmohammad/Code-N-Collab/blob/master/src/Function/languageMapper.js  for other language types
          theme: 'monokai',
          lineWrapping: true,
          smartIndent: true,
          lineNumbers: true,
          readOnly: props.projectUsersPermission.find(userPermission => userPermission.user === auth?.user?.email && userPermission.permission === true) !== undefined || props.whoAdmin === auth?.user?.email || readOnly ? false : true,
          foldGutter: true,
          tabSize: props.tabSize,
          gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
          autoCloseTags: true,
          matchBrackets: true,
          autoCloseBrackets: true,
          // autocomplete:true,
          extraKeys: {
            'Ctrl-Space': 'autocomplete',
          },
        }}
        editorDidMount={(editor) => {
          handleEditorDidMount(editor)
          editor.setSize('100%', '100%')
        }}
      />
      
    </div>
  )
}