import React, { useEffect, useState } from 'react'
import {
  LightningBoltIcon,
  XCircleIcon
} from '@heroicons/react/outline'
import { JSHINT as jshint } from 'jshint';

export const Problem = ({ codeForCheck, languageName }) => {
  const [problems, setProblems] = useState([])

  useEffect(() => {
    checkMistakes(codeForCheck)
  }, [codeForCheck])

  const checkErrors = value => {
    jshint(value.split('\n'));
    return jshint.data().errors ? jshint.data().errors : [];
  };

  const checkMistakes = (code) => {
    if (!code) {
      return
    }
    checkErrors(code)
    setProblems(jshint?.data()?.errors)
    console.warn(jshint.data());
    console.warn(jshint.data().errors);
  };


  const listOfProblems = problems?.map((problem, idx) =>
    <li key={idx}><div className='flex items-center px-3 py-0.5'>{problem.code !== 'E019' ? <LightningBoltIcon className='w-3 h-3 text-[#F4C78C] mx-2' /> : <XCircleIcon className='w-3 h-3 text-[#EC847D] mx-2' />}
      <p className='text-gray-400'> {problem.reason} &nbsp; Line: {problem.line} Character: {problem.character}</p>
    </div></li>
  );


  return (
    <div>
      <div className=' flex flex-col h-[200px] max-h-[200px] w-full border-t border-[#30363D]'>
        <div className='border-b border-[#30363D]'>
          <button className='border-l border-r border-[#30363D] py-1 px-6 text-gray-400'>Problems</button>
        </div>

        {languageName === "JavaScript" ?
          <div className=' h-full overflow-auto scrollbar-hide md:scrollbar-default'>
            <ul>{listOfProblems}</ul>
          </div>
          : <></>
        }
      </div>
    </div>
  )
}


