import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHttp } from '../../hooks/http.hook'
import { Button, Popover } from 'antd';
import { useMessage } from '../../hooks/message.hook'


import {
    ShareIcon,
    UploadIcon,
    DownloadIcon,
    CheckIcon,
    PlusIcon
} from '@heroicons/react/outline'



export const Git = ({ setTreeFromSearch, showModal, roomId }) => {
    const message = useMessage()
    const auth = useSelector(state => state.auth)
    const project = useSelector(state => state.auth.user.projects.find(el => +el.id === +auth.selectedProject))
    const { request } = useHttp()

    const gitInitPop = (
        <div>
            <p className='text-blueGray-400'>Create git repository from this project.</p>
        </div>
    );

    const pushPop = (
        <div>
            <p className='text-blueGray-400'>Add all file to git, commit and push.</p>
            <p className='text-blueGray-400'>git add . && git commit.. && git push</p>
        </div>
    );

    const pullPop = (
        <div>
            <p className='text-blueGray-400'>Pull from git.</p>
        </div>
    );

    const commitPop = (
        <div>
            <p className='text-blueGray-400'>Create a new commit.</p>
        </div>
    );

    const gitCreateRepoPop = (
        <div>
            <p className='text-blueGray-400'>Create the remote origin on Git.</p>
        </div>
    );

    const push = async () => {
        try {
            const gitPush = await request('/api/git/gitPush', 'POST', { roomId }, { authorization: 'Bearer ' + auth.token })
            message(gitPush.message)
        } catch (e) {
            console.log(e)
            message('Что-то пошло не так! gitPush')
        }
    }


    const pull = async () => {
        try {
            const gitPull = await request('/api/git/gitPull', 'POST', { roomId }, { authorization: 'Bearer ' + auth.token })
            message(gitPull.message)
        } catch (e) {
            console.log(e)
            message('Что-то пошло не так! gitPull')
        }
    }





    return (
        <div className='flex gap-2 text-blueGray-400 '>



            <Popover content={gitInitPop} placement="bottom" >
                <ShareIcon className='w-[20px] hover:text-gray-300 cursor-pointer ' id='gitInit'
                    onClick={showModal}
                />
            </Popover>


            <Popover content={gitCreateRepoPop} placement="bottom" >
                <PlusIcon className='w-[20px] hover:text-gray-300 cursor-pointer ' id='gitCreateRepo'
                    onClick={showModal}
                />
            </Popover>



            {/* <Popover content={commitPop} placement="bottom" >
                <CheckIcon className='w-[20px]  hover:text-gray-300 cursor-pointer' id='gitCommit'
                    onClick={showModal} />
            </Popover> */}

            <Popover content={pushPop} placement="bottom" >
                <UploadIcon className='w-[20px]  hover:text-gray-300 cursor-pointer' id='gitPush'
                    onClick={showModal}
                />
            </Popover>

            <Popover content={pullPop} placement="bottom" >
                <DownloadIcon className='w-[20px]  hover:text-gray-300 cursor-pointer'
                    onClick={pull}
                />
            </Popover>


        </div>
    )
}

