import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoaderFull } from '../../components/Loader/LoaderFull'
import HScreen from '../../layout/hScreen'
import { PageTitle } from '../../components/Title/pageTitle'
import Input from '../../components/Input'
import { Button } from '../../components/Button'
import { useMessage } from '../../hooks/message.hook'
import { useHttp } from '../../hooks/http.hook'
import { updateProjectsList } from '../../redux/actions/auth.actions'

export const SettingsPage = () => {
  const auth = useSelector(state => state.auth)
  const project = useSelector(state => state.auth.user.projects.find(el => +el.id === +auth.selectedProject))
  const dispatch = useDispatch()
  const message = useMessage()
  const { loading, request, error, clearError } = useHttp()
  const [form, setForm] = useState({
    userId: '',
    id: '',
    name: '',
    gitUrl: '',
  })

  useEffect(() => {
    try {
      if (auth.user) {
        setForm({
          userId: auth.user.id,
          id: project.id,
          name: project.name,
          gitUrl: project.gitUrl,
        })
      }
    } catch (e) {
      console.log(e)
    }
  }, [auth])

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const updateHandler = async () => {
    try {
      const data = await request('/api/project/updateProject', 'POST', { form }, { authorization: 'Bearer ' + auth.token })

      let projects = JSON.parse(JSON.stringify(auth.user.projects))
      let idx = projects.findIndex(prj => +prj.id === +data.project.id)
      if (idx >= 0) projects[idx] = data.project

      dispatch(updateProjectsList(projects))
      message(data.message)
    } catch (e) {
      console.log(e)
    }
  }


  if (!auth.user) {
    return <LoaderFull />
  }

  return (
    <HScreen>
      <div className='container mt-[75px]'>
        <div className='flex flex-col gap-14'>
          {/*Title*/}
          <PageTitle title='Project settings'/>

          <div className="flex flex-col gap-6 max-w-1/3">
            {/*Form*/}
            <div className="flex flex-col gap-1">
              <span className="text-sm text-white noselect">Название проекта</span>
              <Input
                className="bg-coolGray-600 text-blueGray-300"
                placeholder='Название проекта'
                id="name"
                name="name"
                value={form.name}
                autoComplete="off"
                onChange={changeHandler}
              />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-sm text-white noselect">git URL</span>
              <Input
                className="bg-coolGray-600 text-blueGray-300"
                placeholder='git URL'
                id="gitUrl"
                name="gitUrl"
                value={form.gitUrl}
                onChange={changeHandler}
              />
            </div>

            <div>
              <Button
                size="md"
                className="min-w-[150px]"
                onClick={updateHandler}
                // disabled={loading}
              >
                Update
              </Button>
            </div>

          </div>
        </div>
      </div>
    </HScreen>
  )
}